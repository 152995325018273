<template>
  <div :style="slidePosition" class=" z-4 w-100 h-100">
    <div
      class="position-absolute bg-dark-green-0-75 border-warning text-warning square24"
      style="top:50%; left:35%;width:60%;padding:2.5% 2.5%;transform: translateY(-50%);"
    >
      <h6 class="text-white font-vw-3-15 font-w-600 fade24 " style="opacity:0;">
        2018
      </h6>
      <h6 class="text-white font-vw-3-15 font-w-600 fade24 " style="opacity:0;">
        FOR THE HEALTH OF CANADIANS
      </h6>
      <p class=" text-white font-vw-1-5 text24 mb-0" style="opacity:0;">
        His father officiated TRIUMF’s first big commissioning event, and on
        November 1, 2018, Canada’s 23rd Prime Minister, the Right Honourable
        Justin Trudeau, visited TRIUMF to announce funding for the lab’s latest
        initiative: the Institute for Advanced Medical Isotopes (IAMI). IAMI
        will be a Canadian nuclear medicine and life sciences centre for the
        research, development, and production of medical isotopes to diagnose
        and treat afflictions from cancer to cardiac disease. The presence of
        two generations of Canadian Prime Ministers at TRIUMF speaks to the
        lab’s dynamic 50-year history as a hub for excellent science, a leader
        in innovation, and a training ground for the next generation—a legacy
        that will power discoveries for years to come.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },

  mounted() {
    //this.startLoop()
  },
  methods: {
    slide24(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 1000
      })

      animation
        .add({
          targets: ".square24",
          opacity: [0, 1],
          translateY: ["50%", "-50%"],
          duration: 600,
          delay: 5000,
          easing: "easeInQuart"
        })
        .add({
          targets: ".fade24",

          translateY: ["1vh", "0vh"],
          opacity: [0, 1],
          duration: 1000,
          easing: "easeInOutCubic",
          delay: (el, i) => 22000 + 1200 * i
        })
        .add({
          targets: ".text24",

          opacity: [0, 1],
          duration: 1200,

          translateY: ["35%", "0%"],
          delay: 2000,
          easing: "easeInOutCubic"
        })

      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * (1 - percentage)
      )
    }
  },
  watch: {
    innerPosition() {
      this.slide24(this.innerPosition)
    }
  }
}
</script>

<style></style>
